import request from '@/utils/request';

// 获取面授详情
export const trainDetail = (id, did) => request.get(`study/train/${id}/${did}/detail`)

// 面授评论点赞/取消点赞
export async function trainCommentLike(params = {}) {
  return request.post(`study/train/${params.resourceId}/comment/like`, params);
}
// 删除面授评论/回复
export async function trainCommentDel(params = {}) {
  return request.post(`study/train/${params.resourceId}/comment/del`, params);
}
// 面授评论/回复
export async function trainCommentSubmit(params = {}) {
  return request.post(`study/train/${params.resourceId}/comment/submit`, params);
}
// 面授评论列表
export async function trainComments(params = {}) {
  return request.post(`study/train/${params.resourceId}/comments`, params);
}
// 项目跟踪-获取问卷统计
export async function trackQuestionnaireAnalysis(params = {}) {
  return request.get(`study/activity/questionnaire/${params.taskId}/${params.detailId}/${params.questionnaireId}/analysis`, params)
}
// 提交问卷
export const trainQuestionnaireSubmit = (ids, p) => request.post(`study/train/questionnaire/${ids.taskId}/${ids.detailId}/${ids.questionnaireId}/${ids.evalType}/submit`, p)

// 我的面授
export async function getMyFaceList(params = {}) {
  return request.post(`study/train/list`, params);
}